<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">Document</h1>
      </div>

      <div class="title-tabs mt-3">จดทะเบียน</div>
      <div class="bg-white p-3">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-register-file-type"
            v-model="selected.is_register"
            :options="fileUploadType"
            :aria-describedby="ariaDescribedby"
            name="register-file-type"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-row>
          <b-col cols="12" sm="8" xxl="6">
            <b-row class="align-items-center row-gap-lg">
              <b-col cols="2" class="text-center"> Sort Order</b-col>
              <b-col cols="4">Description </b-col>
              <!-- <b-col cols="4"> Description</b-col> -->
              <b-col cols="1"> </b-col>
              <b-col cols="2" class="text-center"> Required</b-col>
              <b-col cols="3" class="text-center"> Display </b-col>

              <template v-for="(field, index) of list">
                <b-col
                  cols="2"
                  class="text-center"
                  :key="'sort-order' + field.id + '-' + index"
                >
                  <div class="content-between">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      :class="`icon-size ${
                        field.id == 1 || index == 0 ? 'disabled' : 'pointer'
                      }`"
                      @click="sortData(field, index, false, 'list')"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      :class="`icon-size ${
                        field.id == 1 || index == list.length - 2
                          ? 'disabled'
                          : 'pointer'
                      }`"
                      @click="sortData(field, index, true, 'list')"
                    />
                  </div>
                </b-col>
                <b-col cols="4" :key="'name' + field.id + '-' + index">
                  <InputText
                    type="text"
                    placeholder=""
                    class="mb-0"
                    v-model="field.name"
                    :disabled="field.id == 1"
                /></b-col>

                <b-col cols="1" :key="'trash-' + field.id + '-' + index">
                  <font-awesome-icon
                    v-if="field.id != 1"
                    icon="trash-alt"
                    class="cursor-pointer"
                    @click="deleteField(index, 'list')"
                /></b-col>
                <b-col
                  cols="2"
                  class="text-center"
                  :key="'check-box-' + field.id + '-' + index"
                >
                  <b-form-checkbox
                    class="disableCheckbox"
                    :id="`checkbox-profile-register-${index}`"
                    :name="`checkbox-profile-register-${index}`"
                    v-model="field.is_required"
                    :value="1"
                    :unchecked-value="0"
                    @change="handleDefaultChange($event, index, 'list')"
                    :disabled="field.id == 1"
                  >
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="3"
                  :key="'display-box-' + field.id + '-' + index"
                  class="text-center"
                >
                  <b-form-checkbox
                    class="disableCheckbox"
                    :id="`display-profile-register-${field.id}-${index}`"
                    :name="`display-profile-register-${field.id}-${index}`"
                    v-model="field.is_display"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="field.id != 1 && field.is_required == 1"
                  >
                  </b-form-checkbox>
                </b-col>
                <div
                  class="break-normal"
                  :key="'break-line' + field.id + index"
                ></div>
              </template>
              <b-col cols="2" class="text-center"> </b-col>
              <b-col cols="6" lg="5" xl="4" class="d-inline-flex">
                <b-button
                  variant="custom-link mr-auto"
                  @click="addField('list')"
                >
                  Add Document Upload
                </b-button>
              </b-col>
              <b-col cols="4"> </b-col>
              <b-col cols="1"> </b-col>
              <b-col cols="1"> </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="title-tabs mt-3">ไม่จดทะเบียน</div>
      <div class="bg-white p-3">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-not-register-file-type"
            v-model="selected.is_not_register"
            :options="fileUploadType"
            :aria-describedby="ariaDescribedby"
            name="not-register-file-type"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-row>
          <b-col cols="12" sm="8" xxl="6">
            <b-row class="align-items-center row-gap-lg">
              <b-col cols="2" class="text-center"> Sort Order</b-col>
              <b-col cols="4"> Description </b-col>
              <!-- <b-col cols="4"> Description</b-col> -->
              <b-col cols="1"> </b-col>
              <b-col cols="2" class="text-center"> Required</b-col>
              <b-col cols="3" class="text-center"> Display </b-col>
              <!-- <b-col cols="1"> Default</b-col> -->
              <!-- <div class="break-normal"></div> -->
              <template v-for="(field, index) of listNoRegister">
                <b-col
                  cols="2"
                  class="text-center"
                  :key="'sort-order' + field.id + '-' + index"
                >
                  <div class="content-between">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      :class="`icon-size ${
                        field.id == 1 || index == 0 ? 'disabled' : 'pointer'
                      }`"
                      @click="sortData(field, index, false, 'listNoRegister')"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      :class="`icon-size ${
                        field.id == 1 || index == listNoRegister.length - 2
                          ? 'disabled'
                          : 'pointer'
                      }`"
                      @click="sortData(field, index, true, 'listNoRegister')"
                    />
                  </div>
                </b-col>
                <b-col cols="4" :key="'name' + field.id + '-' + index">
                  <InputText
                    type="text"
                    placeholder=""
                    class="mb-0"
                    v-model="field.name"
                    :disabled="field.id == 1"
                /></b-col>

                <b-col cols="1" :key="'trash-' + field.id + '-' + index">
                  <font-awesome-icon
                    v-if="field.id != 1"
                    icon="trash-alt"
                    class="cursor-pointer"
                    @click="deleteField(index, 'listNoRegister')"
                /></b-col>
                <b-col
                  cols="2"
                  class="text-center"
                  :key="'check-box-' + field.id + '-' + index"
                >
                  <b-form-checkbox
                    class="disableCheckbox"
                    :id="`checkbox-profile-no-register-${index}`"
                    :name="`checkbox-profile-no-register-${index}`"
                    v-model="field.is_required"
                    :value="1"
                    :unchecked-value="0"
                    @change="
                      handleDefaultChange($event, index, 'listNoRegister')
                    "
                    :disabled="field.id == 1"
                  >
                  </b-form-checkbox>
                </b-col>
                <b-col
                  cols="3"
                  :key="'display-box-' + field.id + '-' + index"
                  class="text-center"
                >
                  <b-form-checkbox
                    class="disableCheckbox"
                    :id="`display-profile-no-register-${field.id}-${index}`"
                    :name="`display-profile-no-register-${field.id}-${index}`"
                    v-model="field.is_display"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="field.id != 1 && field.is_required == 1"
                  >
                  </b-form-checkbox>
                </b-col>
                <div
                  class="break-normal"
                  :key="'break-line' + field.id + index"
                ></div>
              </template>
              <b-col cols="2" class="text-center"> </b-col>
              <b-col cols="6" lg="5" xl="4" class="d-inline-flex">
                <b-button
                  variant="custom-link mr-auto"
                  @click="addField('listNoRegister')"
                >
                  Add Document Upload
                </b-button>
              </b-col>
              <b-col cols="4"> </b-col>
              <b-col cols="1"> </b-col>
              <b-col cols="1"> </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <FooterAction @submit="submit()" routePath="/setting/company" />
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import DataType from "./type.js";
export default {
  components: { OtherLoading },
  validations: {},

  data() {
    return {
      list: [],
      listNoRegister: [],
      listDelete: [],
      updateList: [],
      isLoading: true,
      pageDetail: {
        name: "",
        header: "",
        updated: "",
      },
      isDefault: null,
      fileUploadType: [
        {
          value: 1,
          text: "Upload .png File",
        },
        {
          value: 2,
          text: "Upload .jpg File",
        },
        {
          value: 3,
          text: "Upload .pdf File",
        },
      ],
      selected: {
        is_register: [],
        is_not_register: [],
      },
    };
  },
  async created() {
    // const page = DataType.find((el) => el.id == this.$route.params.id);
    // this.pageDetail = page;
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const register = await this.axios(`setting/GetDocumentSetting`);
      let data = JSON.parse(JSON.stringify(register.data.detail));
      let list = data.findIndex((el) => el.id == 1);
      data[list].sort_order = 99;
      let $noRegister = JSON.parse(
        JSON.stringify(data.filter((el) => el.is_not_register == 1))
      );
      let $register = JSON.parse(
        JSON.stringify(data.filter((el) => el.is_register == 1))
      );
      this.list = $register.sort((a, b) => a.sort_order - b.sort_order);

      this.listNoRegister = $noRegister.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      let $selectRegister = [];
      let $selectNoRegister = [];
      if ($register[0].is_pdf == 1) $selectRegister.push(3);
      if ($register[0].is_jpg == 1) $selectRegister.push(2);
      if ($register[0].is_png == 1) $selectRegister.push(1);
      if ($noRegister[0].is_pdf == 1) $selectNoRegister.push(3);
      if ($noRegister[0].is_jpg == 1) $selectNoRegister.push(2);
      if ($noRegister[0].is_png == 1) $selectNoRegister.push(1);
      this.selected = {
        is_register: $selectRegister,
        is_not_register: $selectNoRegister,
      };
      this.isLoading = false;
    },
    addField(type) {
      let other = JSON.parse(
        JSON.stringify(this[type].findIndex((el) => el.id == 1))
      );
      let clone = JSON.parse(JSON.stringify(this[type][other]));
      this[type].splice(other, 1);

      this[type].push({
        id: 0,
        is_required: 1,
        is_display: 1,
        name: "เอกสาร",
        is_png: 1,
        is_jpg: 1,
        is_pdf: 1,
        sort_order: this[type].length,
        is_register: type == "list" ? 1 : 0,
        is_not_register: type == "listNoRegister" ? 1 : 0,
      });
      clone.sort_order += 1;
      this[type].push(clone);
    },
    deleteField(index, key) {
      const dele = this[key].splice(index, 1);
      this.listDelete.push(dele[0].id);
    },
    async submit() {
      try {
        this.$bus.$emit("showLoading");
        let payload = {
          document: [
            ...this.list.map((el) => {
              return {
                ...el,
                is_png: this.selected.is_register.includes(1) ? 1 : 0,
                is_jpg: this.selected.is_register.includes(2) ? 1 : 0,
                is_pdf: this.selected.is_register.includes(3) ? 1 : 0,
              };
            }),

            ...this.listNoRegister.map((el) => {
              return {
                ...el,
                is_png: this.selected.is_not_register.includes(1) ? 1 : 0,
                is_jpg: this.selected.is_not_register.includes(2) ? 1 : 0,
                is_pdf: this.selected.is_not_register.includes(3) ? 1 : 0,
              };
            }),
          ],
          delete: this.listDelete,
        };

        const res = await this.axios.put(
          `setting/UpdateDocumentSetting`,
          payload
        );
        this.getList();
        this.$bus.$emit("hideLoading");
        if (res.data.result == 1)
          this.successAlert().then(() => this.$router.push("/setting/company"));
        else this.errorAlert();
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async sortData(items, index, val, key) {
      if (index == 0 && !val) {
        return;
      }
      if (val && index == this[key].length - 2) return;
      if (items.id == 1) return;

      this[key].splice(index, 1);
      index = val ? index + 1 : index - 1;

      this[key].splice(index, 0, items);
      var index = 0;
      for (const fields of this[key]) {
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this[key] = [...this[key]];
        // this.sortArray();
      });
    },
    handleDefaultChange(val, index, key) {
      if (val == 1)
        this.$nextTick(
          () =>
            (this[key] = [
              ...this[key].map((el, i) => {
                if (i == index) return { ...el, is_display: 1 };
                return el;
              }),
            ])
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
  cursor: pointer;
}
.icon-size.disabled {
  color: #bababa;
}
</style>
